import { Dispatch, Fragment, SetStateAction } from "react";
import { Box, Card, Link, TableCell, TableRow } from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";

import { ScreenHeader, SortIcon, Table } from "@APP/components";
import { tableStyles as useStyles } from "@APP/styles";
import { SortType, User, UserSearchResponse, UserSortBy } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { commonFormatDate } from "@APP/constants";
import toCamelCase from "@APP/utils/toCamelCase";

interface HeadCell {
  id: UserSortBy;
  rightAlign: boolean;
  disablePadding: boolean;
  label: string;
}

interface UserProps {
  data: UserSearchResponse[];
  entries: number;
  page: number;
  lastPage: number;
  setPage?: (page: number) => void;
  handleOnEntriesChange: (entries: number) => void;
  order: "asc" | "desc";
  setOrder: Dispatch<SetStateAction<"asc" | "desc">>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
}

const HEADER_CELL_ITEMS: HeadCell[] = [
  { id: UserSortBy.username, rightAlign: false, disablePadding: false, label: "Username" },
  { id: UserSortBy.status, rightAlign: false, disablePadding: false, label: "Status" },
  { id: UserSortBy.orgName, rightAlign: true, disablePadding: false, label: "Organization name" },
  {
    id: UserSortBy.createdAt,
    rightAlign: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: UserSortBy.createdAtTime,
    rightAlign: false,
    disablePadding: false,
    label: "Created Time",
  },
];

const EMPTY_ROW_COL_SPAN = HEADER_CELL_ITEMS.length + 2;

const UserList = ({
  data,
  entries,
  page,
  lastPage,
  setPage,
  handleOnEntriesChange,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}: UserProps) => {
  const classes = useStyles();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const renderHeader = () => (
    <TableRow>
      {HEADER_CELL_ITEMS.map(({ id, label, disablePadding }) => (
        <TableCell
          key={id}
          align="left"
          padding={disablePadding ? "none" : "normal"}
          sortDirection={order}
          onClick={() => (data?.length ? handleRequestSort(toCamelCase(label)) : null)}
          style={{ cursor: data?.length ? "pointer" : "default" }}>
          <Box display="flex" alignItems="center" data-testid={`${label}-field-label`}>
            {label}
            {!!data?.length && (
              <SortIcon active={orderBy === toCamelCase(label)} type={order as SortType} />
            )}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderRows = ({ id, username, status, orgName, orgId, meta: { createdAt } }: User) => {
    return (
      <Fragment key={id}>
        <TableRow className={classes.tableRow}>
          <TableCell>{username}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>
            <Link href={`${SCREEN_PATHS.ORG_DETAILS}/${orgId}`}>{orgName || " "}</Link>
          </TableCell>
          <TableCell>
            {createdAt ? formatInTimeZone(createdAt, "UTC", commonFormatDate) : "-"}
          </TableCell>
          <TableCell>{createdAt ? formatInTimeZone(createdAt, "UTC", "HH:mm") : "-"}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={EMPTY_ROW_COL_SPAN} align="center">
        No Matching Users found.
      </TableCell>
    </TableRow>
  );

  return (
    <Box my={4}>
      <ScreenHeader title="Users" />
      <Card elevation={12}>
        <Table
          renderHeader={renderHeader}
          data={data || []}
          showPagination={true}
          renderRows={renderRows}
          onEntriesChange={handleOnEntriesChange}
          onPageChange={setPage}
          entries={entries}
          page={page}
          lastPage={lastPage}
          renderEmptyDataRow={renderEmptyDataRow}
        />
      </Card>
    </Box>
  );
};

export default UserList;
